<template>
    <div style="margin-top: 10px; border: 1px solid #ebebeb; margin-bottom: 80px;">
        <div style="background: #fff; border-bottom: 1px solid #ebebeb;" >
            <div style=" margin: auto; padding: 16px; display: flex;">
                <div class="nav-item">
                    <a-badge size="small" :count="likeAndCollectionQuantity">
                        <a-button size="small" type="link" :class="[ isActiveBtn('bbsMessageLikeAndCollect') ? 'active-btn' : 'inactive-btn'  ]"  @click="handleGoTo('bbsMessageLikeAndCollect')">
                            点赞与收藏
                        </a-button>
                    </a-badge>
                </div>
                <div class="nav-item">
                    <a-badge size="small" :count="commentQuantity">
                        <a-button size="small" type="link" :class="[ isActiveBtn('bbsMessageComment') ? 'active-btn' : 'inactive-btn'  ]" @click="handleGoTo('bbsMessageComment')">
                            评论
                        </a-button>
                    </a-badge>
                </div>
                <div class="nav-item">
                    <a-badge size="small" :count="userQuantity">
                        <a-button size="small" type="link" :class="[ isActiveBtn('bbsMessageNewFollowers') ? 'active-btn' : 'inactive-btn'  ]"  @click="handleGoTo('bbsMessageNewFollowers')">
                            新增粉丝
                        </a-button>
                    </a-badge>
                </div>
            </div>
        </div>
        <div >
            <router-view @onActiveChange="onActiveChange">  </router-view>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue-demi';
import { mapState } from 'vuex';

export default {
    props: {

    },
    setup(props, context) {
        const { $route, $router } = context.root;

        const activeName = ref('');

        const isActiveBtn = (name) => {
            return activeName.value === name;
        }

        const onActiveChange = (name) => {
            activeName.value = name;
        }

        const handleGoTo = (name) => {
            $router.replace({
                name: name
            })
        }
       
        return {
            isActiveBtn,
            handleGoTo,
            onActiveChange,
            ...mapState({
                 // 新增评论数量
            commentQuantity: (state) => state.bbs.commentQuantity,
             // 新增粉丝数量
            userQuantity: (state) => state.bbs.userQuantity,
            // 最新点赞和收藏数量
            likeAndCollectionQuantity: (state) => state.bbs.likeAndCollectionQuantity,
            })
        }
    }
}

</script>

<style lang="less" scoped>
.nav-item + .nav-item {
    margin-left: 28px;
}
.active-btn {
    color: #1890ff;
}
.inactive-btn {
    color: rgba(0, 0, 0, 0.65);
}
</style>